:root {
  --ion-color-primary: #22aed1;
  --ion-color-primary-rgb: 34, 174, 209;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #1e99b8;
  --ion-color-primary-tint: #38b6d6;

  --ion-color-secondary: #7768ae;
  --ion-color-secondary-rgb: 119, 104, 174;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #695c99;
  --ion-color-secondary-tint: #8577b6;

  --ion-color-tertiary: #064789;
  --ion-color-tertiary-rgb: 6, 71, 137;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #053e79;
  --ion-color-tertiary-tint: #1f5995;

  --ion-color-success: #5fad56;
  --ion-color-success-rgb: 95, 173, 86;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #54984c;
  --ion-color-success-tint: #6fb567;

  --ion-color-warning: #ffbc42;
  --ion-color-warning-rgb: 255, 188, 66;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0a53a;
  --ion-color-warning-tint: #ffc355;

  --ion-color-danger: #f45b69;
  --ion-color-danger-rgb: 244, 91, 105;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #d7505c;
  --ion-color-danger-tint: #f56b78;

  --ion-color-dark: #495d63;
  --ion-color-dark-rgb: 73, 93, 99;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #405257;
  --ion-color-dark-tint: #5b6d73;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f8f7f9;
  --ion-color-light-rgb: 248, 247, 249;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #dad9db;
  --ion-color-light-tint: #f9f8fa;
}
